const firebaseConfig = {
  // apiKey: "AIzaSyBc1-qSpbFnsVg4Z716hEP9ZlzUdcnnB0E",
  // authDomain: "app-inventaires-v2.firebaseapp.com",
  // databaseURL: "https://app-inventaires-v2.europe-west1.firebasedatabase.app",
  // projectId: "app-inventaires-v2",
  // storageBucket: "app-inventaires-v2.appspot.com",
  // messagingSenderId: "274526075752",
  // appId: "1:274526075752:web:27e01b57949c7e7c4e34dd",
  // measurementId: "G-BJG0M7S5LJ",
  apiKey: "AIzaSyDO2HarhdKd65MX0oyZH0AcqUF0AaeiXls",
  authDomain: "application-inventaires.firebaseapp.com",
  databaseURL:
    "https://application-inventaires-v2.europe-west1.firebasedatabase.app",
  projectId: "application-inventaires",
  storageBucket: "application-inventaires.appspot.com",
  messagingSenderId: "192846224574",
  appId: "1:192846224574:web:8fcd2e6ae4e31df4bf92ef",
  measurementId: "G-H42ELMXR27",
};

export { firebaseConfig };
