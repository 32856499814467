import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/database";
import { firebaseConfig } from "@/firebase/firebaseConfig";

// init firebase
firebase.initializeApp(firebaseConfig);

// init functions
const projectFunctions = firebase.functions();

// init services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectDatabase = firebase.database();

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;
const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
const serverValue = firebase.database.ServerValue;
const authPersistence = firebase.auth.Auth.Persistence;

// Emulators
if (process.env.NODE_ENV !== "production") {
  firebase.functions().useEmulator("localhost", 5001);
  firebase.database().useEmulator("localhost", 9000);
  firebase
    .auth()
    .useEmulator("http://localhost:9099", { disableWarnings: true });
  firebase.firestore().useEmulator("localhost", 8090);
}

export {
  firebaseConfig,
  projectFirestore,
  projectAuth,
  projectDatabase,
  projectFunctions,
  timestamp,
  arrayUnion,
  serverValue,
  authPersistence,
};
