<template>
  <!--  <template v-if="pending"><small class="flex justify-content-center align-items-center opacity-50" style="min-height: 60px">Chargement...</small></template>-->
  <!--  <template v-else>-->
  <header
    class="flex justify-content-between align-items-center"
    :class="route.name === 'Admin' ? 'admin' : ''"
  >
    <router-link custom v-slot="{ navigate }" :to="{ name: 'Home' }">
      <div class="title flex align-items-center clickable" @click="navigate">
        <i :class="icon" class="mr-2 text-primary-dark"></i>
        <span>{{ header }}</span>
      </div>
    </router-link>
    <Button
      icon="fa fa-wifi"
      class="p-button-rounded p-button-text ml-2"
      :class="!isOnline ? 'p-button-danger' : 'p-button-sm'"
    />
    <Button
      :label="truncate(user?.name, { length: 16, separator: /,? +/ })"
      aria-haspopup="true"
      aria-controls="overlay_menu"
      icon="fa fa-user"
      @click="toggle"
      class="p-button-secondary p-button-rounded p-button-sm ml-auto"
    />
    <Menu :model="items" id="overlay_menu" ref="menu" popup />
  </header>
</template>
<!--</template>-->
<script>
import { onBeforeMount, ref, watch } from "vue";
import { getUser } from "@/composables/getUser";
import useLogout from "../composables/useLogout";
import useNetwork from "../utils/useNetwork";
import useSecurity from "../security/rules";
import { truncate } from "lodash";
import { useRoute, useRouter } from "vue-router";

export default {
  props: {
    icon: String,
    header: String,
  },
  setup() {
    const { user } = getUser();
    const { logout } = useLogout();
    const { isOnline } = useNetwork();
    const router = useRouter();
    const { can } = useSecurity();

    // const { docs: storages, pending: storagePending } =
    //   useDatabase("storages").getAll();

    // const pending = computed(() => !!storagePending.value);

    // const selectedStorage = computed(() =>
    //   storages.value.find((s) => s.id === route.params.storageId)
    // );

    onBeforeMount(async () => {
      isUserAdmin.value = await can("accessDashboard");
    });

    const isUserAdmin = ref(false);
    const route = useRoute();

    const menu = ref();
    const toggle = () => {
      menu.value.toggle(event);
    };
    const items = ref([
      // {
      //   label: "En ligne",
      //   icon: "fa fa-wifi",
      // },
      {
        label: "Administration",
        icon: "fa fa-cogs",
        // to: { name: "Admin" },
        visible: () => isUserAdmin.value,
        command: () => router.replace({ name: "Admin" }),
      },
      {
        label: "Se deconnecter",
        icon: "pi pi-fw pi-power-off",
        command: () => logout(),
      },
    ]);

    watch(isOnline, (val) => {
      if (val) {
        items.value[0].label = "En ligne";
        items.value[0].class = "text-primary";
      } else {
        items.value[0].label = "Hors ligne";
        items.value[0].class = "text-danger";
      }
    });

    return {
      user,
      items,
      toggle,
      menu,
      isOnline,
      truncate,
      route,
      // pending,
      // selectedStorage,
    };
  },
};
</script>

<style lang="scss" scoped>
header {
  height: 60px;
  background-color: white;
  box-shadow: 0 4px 8px rgba($secondary-light, 0.1);
  padding: 0 1rem;
  .title {
    font-weight: 500;
  }
  .p-chip {
    font-size: 0.9rem;
  }
  .p-menu .p-menuitem-link .p-menuitem-icon {
    color: $primary !important;
    font-size: 0.75rem;
  }
  &.admin {
    background-color: $warning;
  }
}
</style>
